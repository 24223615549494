/**
 * Given an array of tasks, a taskId and a new time for the given id, it checks whether or not it
 * overlaps another tasks of the day.
 * @function isTaskOverlapping - Returns a boolean
 * @function overlappedTasks - Returns the array of tasks that are being overlapped
 *
 * @param {Task[]} tasks - Array of tasks belonging to a day.
 * @return {taskId} Id of task that's being modified.
 * @param {start: number, end: number} newTime - new start/end time for the modified task.
 */

interface Props {
  tasks: any;
  newTime: {
    start: number;
    end: number;
  };
  taskId: string;
}

const isTaskOverlapping = (props: Props) => overlappedTasks(props).length > 0;

const overlappedTasks = ({ tasks, newTime, taskId }: Props) =>{

  return  tasks
      .filter((t: any) => t.UserTaskHourCode !== parseInt(taskId))
      .filter((t: any) => {
        return newTime.start < t.end && t.start < newTime.end;
      });

}

export { overlappedTasks, isTaskOverlapping };
