import React, { useState, useEffect } from "react";
import {
  IonAlert,
  IonLabel,
  IonItem,
  IonButton,
  IonText,
  IonTextarea,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonDatetime
} from "@ionic/react";

import { useHistory } from "react-router-dom";
import Entries from "../../containers/entry";
import moment from "moment";

import "./EditTaskForm.css";
import { overlappedTasks } from "../../utils/TaskOverlapping";
import timeStringToFloat from "../../utils/timeStringToFloat";

interface checkProps {
  formState: any;
  setError: Function;
  entries: any;
  entryId: any;
}

const checkErrors = ({ formState, setError, entries, entryId }: checkProps) => {
  const start = timeStringToFloat(formState.startTime);
  const end = timeStringToFloat(formState.endTime);
  

  if (start > end) {
    setError("Starting time can't be later than ending time");
  } else if (start === end) {
    setError("Tasks need to be at least 1 minute long");
  } else {
    const dateTasks = entries
      .filter((i: any) => i.TaskDate === formState.date)
      .map((task: any) => ({
        ...task,
        start: timeStringToFloat(task.StartTime),
        end: timeStringToFloat(task.EndTime)
      }));

    const overlappedtasks = overlappedTasks({
      tasks: dateTasks,
      newTime: { start, end },
      taskId: entryId
    });

    if (overlappedtasks.length > 0) {
      const overlapped = overlappedtasks[0];
      setError(
        `Task can't be saved as it would over lap ${overlapped.TaskName} which goes from ${overlapped.StartTime} to ${overlapped.EndTime}`
      );
    } else {
      setError("");
    }
  }
};

interface TaskFormProps {
  entries: any;
  entryId: any;
  save: any;
  remove: any;
  fetch: any;
  setLoading: any;
}

const EditTaskForm = ({
  entries,
  entryId,
  save,
  remove,
  fetch,
  setLoading
}: TaskFormProps) => {
  const history = useHistory();
  const { setSelectedIndex } = Entries.useContainer();
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");

//TEMP FIX TO ERROR BOUNDARY
const useAsyncError = () => {

  //@ts-ignore
  const [_, setError] = React.useState();

  return React.useCallback(
    e => {
      setError(() => {
        throw e;
      });
    },
    [setError],
    );
  };

const throwError =  useAsyncError()
//END OF TEMP FIX

  const selectedEntry = entries.find(
    (entry: any) => entry.UserTaskHourCode === parseInt(entryId)
  );

  const [formState, setFormState] = useState({
    date: selectedEntry?.TaskDate,
    startTime: selectedEntry?.StartTime,
    endTime: selectedEntry?.EndTime,
    comments: selectedEntry?.Comments
  });

  useEffect(
    () =>
      checkErrors({
        formState,
        setError,
        entries,
        entryId
      }),
    [formState, setError, entries, entryId]
  );

  const close = () => {
    setLoading(false);
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(`/day/${moment().format("YYYY-MM-DD")}`);
    }
  };

  //NOT USED?!
  // const handleSetDate = (e: any) => {
  //   setFormState({
  //     ...formState,
  //     date: e.target.value
  //   });
  // };

  const handleSetStartTime = (e: any) => {

    setFormState({
      ...formState,
      startTime: e.target.value
    });
  };

  const handleSetEndTime = (e: any) => {
    setFormState({
      ...formState,
      endTime: e.target.value
    });
  };

  const handleSetComments = (e: any) => {
    setFormState({
      ...formState,
      comments: e.target.value
    });
  };

  const saveAndClose = () => {
    setLoading(true);
    save(
      { UserTaskHourCode: parseInt(selectedEntry.UserTaskHourCode) },
      {
        ...selectedEntry,
        StartTime: `${formState.date} ${formState.startTime}`,
        EndTime: `${formState.date} ${formState.endTime}`,
        Comments: formState.comments
      }
    ).then(() => {
      fetch({}, {}).then(() => close());
    }).catch((error: any)=>{
      throwError(new Error("Async Error"))
      }
        );;
  };

  const deleteAndClose = () => {
    setLoading(true);
    setSelectedIndex(null);
    remove(
      { UserTaskHourCode: parseInt(selectedEntry.UserTaskHourCode) },
      {
        UserTaskHourCode: parseInt(selectedEntry.UserTaskHourCode),
        TaskDate: selectedEntry.TaskDate
      }
    ).then(() => {
      fetch({}, {}).then(() => close());
    }).catch((error:any)=>{
      throwError(new Error("Async Error"))
      }
        );;
  };

  return (
    <IonContent className="ion-padding">
      <IonGrid>
        <IonRow className="ion-justify-content-center header ion-grap">
          <IonText className="ion-text-center">
            <h3>{selectedEntry?.TaskName || "Deleting..."}</h3>
          </IonText>
        </IonRow>
        <form>
          <IonRow className="ion-padding-top">
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">Start Time </IonLabel>
                <IonDatetime
                  name="startTime"
                  displayFormat="HH:mm"
                  minuteValues="0, 5, 10, 15, 20, 25,30,35,40.45,50,55,"
                  onIonChange={handleSetStartTime}
                  value={formState.startTime}
                />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">End Time </IonLabel>
                <IonDatetime
               displayFormat="HH:mm"
                  name="endTime"
                  minuteValues="0, 5, 10, 15, 20, 25, 30, 35 ,40 , 45, 50, 55,"
                  onIonChange={handleSetEndTime}
                  value={formState.endTime}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">Date </IonLabel>
                {/* currently not working, added to buglist, current fix is to make it static */}
                <IonText
                  // type="date"
                  // name="date"
                  // onInput={handleSetDate}
                  // value={formState.date}
                >{formState.date}</IonText>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding-bottom">
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Comments</IonLabel>
                <IonTextarea
                  onInput={handleSetComments}
                  value={formState.comments}
                  placeholder="No comments"
                />
              </IonItem>
            </IonCol>
          </IonRow>
          {error && <p className="edit-error">{error}</p>}
          <IonRow className="ion-padding-vertical ion-justify-content-end">
            <IonButton
              data-test="delete-button"
              className="justify-item-start"
              fill="clear"
              onClick={() => setShowAlert(true)}
            >
              DELETE TASK
            </IonButton>
            <IonButton fill="clear" onClick={close}>
              CANCEL
            </IonButton>
            <IonButton
              className="fill-button"
              fill="solid"
              onClick={saveAndClose}
              disabled={!!error}
            >
              SAVE
            </IonButton>
          </IonRow>
        </form>
      </IonGrid>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        message={"Are you sure you want to <strong>delete</strong> the task?"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary"
          },
          {
            text: "Confirm",
            cssClass: "primary",
            handler: () => {
              deleteAndClose();
            }
          }
        ]}
      />
    </IonContent>
  );
};

export default EditTaskForm;
