import * as d3 from "d3";
import { isTaskOverlapping } from "../../../utils/TaskOverlapping";
import { HALF_PI, TWO_PI } from "./constants";
import { roundQuarter, currentTimes } from "./util";
import { thetaToTime, timeToTheta } from "./timeFunctions";
import { decimalToTimeString } from "../../../utils/timeFormatting";
import { getPeriod } from "../utilities/timeFunctions";

let duration: number,
  midTime: number,
  startTime: number,
  endTime: number,
  guardTime: number;

let refTime = 0;

const [LATER, EARLIER, DEFAULT] = ["later", "earlier", "default"];

const getDragType = (): string => {
  let dragType: string;
  const c = d3.event.sourceEvent.target.getAttribute("class");
  if (c) {
    if (c.search(LATER) !== -1) {
      dragType = LATER;
    } else if (c.search(EARLIER) !== -1) {
      dragType = EARLIER;
    } else {
      dragType = DEFAULT;
    }
  } else {
    dragType = DEFAULT;
  }
  return dragType;
};

const getDragTheta = (props: any): number => {
  const cx: number = props.width / 2;
  const cy: number = props.height / 2;
  let theta: number = Math.atan2(cy - d3.event.y, d3.event.x - cx);
  theta -= HALF_PI;

  if (theta < 0) theta += TWO_PI;
  theta = TWO_PI - theta;
  return theta;
};

const setRefTime = (ttime: number, time: number, props: any): void => {
  if (props.clockType === 12) {
    if (ttime > 12 && ttime < 13 && time > 9) {
      // crossed from pm to am
      refTime = 0;
    } else if (time >= 0 && time < 1 && ttime > 9) {
      // crossed from am to pm
      refTime = 12;
    }
  } else {
    refTime = 0;
  }
};

const startingTaskDrag = (props: any): void => {
  currentTimes.start = roundQuarter(currentTimes.start);
  currentTimes.end = roundQuarter(currentTimes.end);
  
  duration = Number(currentTimes.end) - Number(currentTimes.start);
  midTime = Number(currentTimes.start) + Number(duration) / 2.0;
  startTime = Number(currentTimes.start);
  endTime = Number(currentTimes.end);
  const dt: string = getDragType();
  refTime = 0;
  if (dt === DEFAULT && midTime > 12) {
    refTime = 12;
  }
  if (dt === EARLIER && startTime > 12) {
    refTime = 12;
  }
  if (dt === LATER && endTime > 12) {
    refTime = 12;
  }
  if (props.clockType === 12) {
    guardTime = 6;
  } else {
    guardTime = 12;
  }
};

const stoppingTaskDrag = (props: any): void => {
  if (props.selectedTaskIndex === null) {
    return;
  }
  //This checks if the start and end times are the same and reverts them to back previous value if they are
if (parseFloat(currentTimes.start) == parseFloat(currentTimes.end)) {
  [currentTimes.start, currentTimes.end] = [
    props.taskData[props.selectedTaskIndex].start, props.taskData[props.selectedTaskIndex].end
  ]  
  props.handler(
    currentTimes, true
  )
}

// this checks if the handles have swapped sides and switches the values 
  if (parseFloat(currentTimes.start) > parseFloat(currentTimes.end)) {
    [currentTimes.start, currentTimes.end] = [
      currentTimes.end,
      currentTimes.start
    ];
  }

//This checks if the task is overlapping and reverts to last valid value if it is
  if(
    isTaskOverlapping({
      tasks: props.taskData,
      newTime: {
        start: Number(currentTimes.start),
        end: Number(currentTimes.end)
      },
      taskId: props.taskData[props.selectedTaskIndex].UserTaskHourCode
    })
    ){
  [currentTimes.start, currentTimes.end] = [
    props.taskData[props.selectedTaskIndex].start, props.taskData[props.selectedTaskIndex].end
  ] 
}


    props.handler(
      currentTimes,
      isTaskOverlapping({
        tasks: props.taskData,
        newTime: {
          start: Number(currentTimes.start),
          end: Number(currentTimes.end)
        },
        taskId: props.taskData[props.selectedTaskIndex].UserTaskHourCode
      })
    );
};

const taskDrag = (ref: any, props: any): void => {
  const currentTheta: number = getDragTheta(props);
  const time: number = thetaToTime(currentTheta, props);
  // don't go past 00:00AM or 24:00PM
  setRefTime(midTime, time, props);
  let t: number = time + duration / 2;
  if (props.clockType === 12) t += 12;
  // early guard
  if (
    startTime >= 0 &&
    startTime < 1 &&
    (time - duration / 2 < 0 || time > 6)
  ) {
    startTime = 0;
    endTime = duration;
  }
  // late guard
  else if (endTime > 23 && (t >= 24 || time < guardTime)) {
    endTime = 24;
    startTime = 24 - duration;
  } else {
    startTime = refTime + time - duration / 2;
    endTime = refTime + time + duration / 2;
    midTime = refTime + time;
  }
  currentTimes.start = roundQuarter(startTime);
  currentTimes.end = roundQuarter(endTime);
  updateTask(ref, props);
};

const taskEarlierDrag = (ref: any, props: any): void => {
  const currentTheta: number = getDragTheta(props);
  const time: number = thetaToTime(currentTheta, props);
  setRefTime(startTime, time, props);
  if (startTime >= 0 && startTime < 1 && time > guardTime) {
    startTime = 0;
    //return
  } else {
    startTime = refTime + time;
  }
  currentTimes.start = roundQuarter(startTime);
  updateTask(ref, props);
};

const taskLaterDrag = (ref: any, props: any): void => {
  const currentTheta: number = getDragTheta(props);
  const time: number = thetaToTime(currentTheta, props);
  setRefTime(endTime, time, props);
  if (endTime > 23 && time > 0 && time < guardTime) {
    endTime = 24;
  } else {
    endTime = refTime + time;
  }
  currentTimes.end = roundQuarter(endTime);
  updateTask(ref, props);
};

const updateTask = (ref: any, props: any, locked: boolean = false): void => {
  const createArc = d3
    .arc()
    .innerRadius(props.innerRadius)
    .outerRadius(props.outerRadius);
  const svg = d3.select(ref.current);
  const pathData = {
    startAngle: timeToTheta(currentTimes.start, props),
    endAngle: timeToTheta(currentTimes.end, props)
  };
  let r: number = 0.5 * (props.innerRadius + props.outerRadius);
  // Adjust starting handler and its time
  svg
    .select(".earlier")
    .attr(
      "transform",
      `translate(${r * Math.sin(pathData.startAngle)} ${-r *
        Math.cos(pathData.startAngle)})`
    );
  const earlierHandle = svg.select(".earlier-time");
  const offset: number = locked ? 0.15 : 0.25;
  earlierHandle.attr(
    "transform",
    `translate(${r * Math.sin(pathData.startAngle - offset)} ${-r *
      Math.cos(pathData.startAngle - offset)})`
  );
  earlierHandle
    .select(".hour")
    .text(decimalToTimeString(Number(currentTimes.start)));
  earlierHandle.select(".period").text(getPeriod(currentTimes.start));
  // Adjust ending handler and its time
  svg
    .select(".later")
    .attr(
      "transform",
      `translate(${r * Math.sin(pathData.endAngle)} ${-r *
        Math.cos(pathData.endAngle)})`
    );
  const laterHandle = svg.select(".later-time");
  laterHandle.attr(
    "transform",
    `translate(${r * Math.sin(pathData.endAngle + offset)} ${-r *
      Math.cos(pathData.endAngle + offset)})`
  );
  laterHandle
    .select(".hour")
    .text(decimalToTimeString(Number(currentTimes.end)));
  laterHandle.select(".period").text(getPeriod(currentTimes.end));
  if (locked) {
    svg.select(".handles").attr("class", "handles non-editable");
  } else {
    svg.select(".handles").attr("class", "handles");
    const path = svg.select(".edit-arcpath");
    //@ts-ignore
    path.attr("d", createArc(pathData));
    if (
      isTaskOverlapping({
        tasks: props.taskData,
        newTime: {
          start: Number(currentTimes.start),
          end: Number(currentTimes.end)
        },
        taskId: props.taskData[props.selectedTaskIndex].UserTaskHourCode
      })
    ) {
      path.attr("class", "arcpath edit-arcpath overlapping");
    } else {
      path.attr("class", "arcpath edit-arcpath");
    }
    // Center Icon
    let radialG = svg.select(".radials");
    let image = radialG.select(
      `#icon_${props.taskData[props.selectedTaskIndex].UserTaskHourCode}`
    );
    let iconRadius = props.outerRadius - 30;
    const angle =
      pathData.startAngle + (pathData.endAngle - pathData.startAngle) / 2;
    image.attr(
      "transform",
      `translate(${iconRadius * Math.sin(angle) - 8} ${-iconRadius *
        Math.cos(angle) +
        8})`
    );
    // Edit button
    svg
      .select(".edit")
      .attr(
        "transform",
        `translate(${props.outerRadius * Math.sin(angle)} ${-props.outerRadius *
          Math.cos(angle)})`
      );
  }
};

const addDragHandlers = (ref: any, props: any): void => {
  const svg: any = d3.select(ref.current);
  svg.selectAll(".draggable-text").call(
    d3
      .drag()
      .container(svg.node())
      .on("start", () => {
        startingTaskDrag(props);
      })
      .on("drag", () => {
        taskDrag(ref, props);
      })
      .on("end", () => {
        stoppingTaskDrag(props);
      })
  );
  svg.selectAll(".draggable.arc").call(
    d3
      .drag()
      .container(svg.node())
      .on("start", () => {
        startingTaskDrag(props);
      })
      .on("drag", () => {
        taskDrag(ref, props);
      })
      .on("end", () => {
        stoppingTaskDrag(props);
      })
  );
  svg.selectAll(".draggable.earlier").call(
    d3
      .drag()
      .container(svg.node())
      .on("start", () => {
        startingTaskDrag(props);
      })
      .on("drag", () => {
        taskEarlierDrag(ref, props);
      })
      .on("end", () => {
        stoppingTaskDrag(props);
      })
  );
  svg.selectAll(".draggable.later").call(
    d3
      .drag()
      .container(svg.node())
      .on("start", () => {
        startingTaskDrag(props);
      })
      .on("drag", () => {
        taskLaterDrag(ref, props);
      })
      .on("end", () => {
        stoppingTaskDrag(props);
      })
  );
};

export { addDragHandlers, updateTask };
