import {
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";

import React, { useState } from "react";
import { useResource, useFetcher } from "rest-hooks";
import { useParams } from "react-router-dom";

import EntryResource from "../resources/entries";
import EditTaskForm from "../components/EditTaskForm";

const EditTask: React.FC = () => {
  const { UserTaskHourCode } = useParams();
  const entries = useResource(EntryResource.listShape(), {});

  const save = useFetcher(EntryResource.update());
  const remove = useFetcher(EntryResource.delete());
  const fetchEntries = useFetcher(EntryResource.listShape());

  const [loading, setLoading] = useState(false);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary ion-text-center">
          <IonTitle>Edit task</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={loading} message="Loading ..." />
        <EditTaskForm
          entries={entries}
          entryId={UserTaskHourCode}
          save={save}
          remove={remove}
          fetch={fetchEntries}
          setLoading={setLoading}
        />
      </IonContent>
    </IonPage>
  );
};

export default EditTask;
