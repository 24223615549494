interface Element {
  open(): () => void;
  toggle(): () => void;
  close(): () => void;
}

const toggleIonicMenu = (id: string) => {
  const menu = document.querySelector(id) as Element | null;
  if (menu) menu.toggle();
};

export default toggleIonicMenu;
