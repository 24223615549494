/**
 * Given an array of tasks it returns an array of free spaces for a new task
 * DEFAULT_TIME is how long tasks are by default. Measured in hours as a float
 * @param {*} todayTasksHours - an array of taskHour objects to compare.
 * @return {[number, number][]} - an array of freeGaps. Free gaps are model as
 * a tuple with the starting time and the ending time.
 */

/**
 * @freeSpaces: { acc : {[number, [number,number][]]}, current: Task, index: number }
 *   The acc first value indicates the ending time of the last task checked.
 *   The acc second value is an array of free spaces
 *     Free spaces are a tuple of numbers
 *     Its first value indicates the starting time of the free gap
 *     The second value indicates the ending time of the free gap
 */

const DEFAULT_TIME: number = 1;

const spacesForNewTask = (
  todayTasksHours: Array<any>
): Array<Array<number>> => {
  const orderedTasks: Array<any> = todayTasksHours.sort(
    (a, b) => a.start - b.start
  );

  if (orderedTasks.length === 0) return [[0, 24]];

  const freeSpaces: Array<any> = orderedTasks.reduce((acc, current, index) => {
    if (index === 0) {
      const initialFreeSpace =
        current.start >= DEFAULT_TIME ? [[0, current.start]] : [];
      return [current.end, initialFreeSpace];
    }

    return [
      current.end,
      current.start - acc[0] >= DEFAULT_TIME
        ? [...acc[1], [acc[0], current.start]]
        : acc[1]
    ];
  }, []);

  return 24 - freeSpaces[0] >= DEFAULT_TIME
    ? [...freeSpaces[1], [freeSpaces[0], 24]]
    : freeSpaces[1];
};

export default spacesForNewTask;
