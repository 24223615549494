import React, { Suspense } from "react";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonLoading
} from "@ionic/react";

import "./TaskMenu.css";

import toggleIonicMenu from "../../utils/toggleIonicMenu";
import EntriesContainer from "../../containers/entry";

import EntryResource from "../../resources/entries";

import moment from "moment";

import { useFetcher } from "rest-hooks";

const TaskListItem = ({
  name,
  handleClick
}: {
  name: string;
  handleClick: () => void;
}) => (
  <IonItem className="taskItem" onClick={handleClick}>
    <IonLabel>{name}</IonLabel>
  </IonItem>
);

const TaskMenu = ({
  tasks,
  setNewTaskStatus,
  setTaskIndex
}: {
  tasks: any[];
  setNewTaskStatus: Function;
  setTaskIndex: Function;
}) => { 
  //TEMP FIX TO ERROR BOUNDARY
    const useAsyncError = () => {

      //@ts-ignore
      const [_, setError] = React.useState();


       //Log to fix error of not using "_"
      return React.useCallback(
        e => {
          setError(() => {
            throw e;
          });
        },
        [setError],
        );
      };

    const throwError =  useAsyncError()
//END OF TEMP FIX

  const entriesC = EntriesContainer.useContainer();
  const {
    eData: { date }
  } = entriesC;
  const create = useFetcher(EntryResource.createShape());
  const fetchEntries = useFetcher(EntryResource.listShape());
  const createTimeEntry: any = async (task: any) => {
    const midnightCheck =
      date.hours() === 0 && date.minutes() === 0 && date.seconds() === 0
        ? 1
        : 0;
    const entryWithDate: any = {
      ...task,
      UserTaskHourCode: Math.floor(Math.random() * 100) + 1000,
      TaskDate: moment(date)
        .subtract(midnightCheck, "seconds")
        .format("YYYY-MM-DD"),
      StartTime: moment(date)
        .seconds(0)
        .subtract(1, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      EndTime: moment(date)
        .seconds(0)
        .subtract(midnightCheck, "seconds")
        .format("YYYY-MM-DD HH:mm:ss")
    };

    setTaskIndex(null);
    setNewTaskStatus("Creating");
    await create({}, entryWithDate).catch((error:any)=>{
      throwError(new Error("Async Error"))
      }
        );
    await fetchEntries({}, {});
    setNewTaskStatus("Created");
  };

  const Loading = () => (
    <IonContent>
      <IonLoading isOpen={true} message="Loading ..." />
    </IonContent>
  );

  return (
    <IonMenu
      side="start"
      //contentId="task-menu"
      id="task-menu"
      menuId="task-menu"
      type="overlay"
    >
      <Suspense fallback={<Loading />}>
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>Clockwork</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList data-test="task-list">
            {tasks &&
              tasks.map(task => (
                <TaskListItem
                  name={task.TaskName}
                  key={task.TaskCode}
                  handleClick={() => {
                    createTimeEntry(task);
                    toggleIonicMenu("#task-menu");
                  }}
                />
              ))}
            {(!tasks || tasks.length === 0) && (
              <p className="ion-padding">You have no tasks assigned to you.</p>
            )}
          </IonList>
          {/* //not in use currently */}
          {/* <IonFooter>
            <IonItem>
              <IonIcon ios={search.ios} md={search.md} />{" "}
              <IonLabel>Search Tasks</IonLabel>
            </IonItem>
          </IonFooter> */}
        </IonContent>
      </Suspense>
    </IonMenu>
  );
};

export default TaskMenu;
