import { routes } from "./config";

async function checkInternetConnection() {
  try {
    const res = await fetch(routes.user.get, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }) 
    if (res?.status >= 200 && res?.status <= 299) {
      return res?.status;
    } else if (res?.status >= 400 && res?.status <= 599) {
      return {
        status: res?.status,
        success: false,
        message: "error when performing internet connection check",
      };
    } else throw new Error("No internet connection detected");
  } catch (error) {
    return 500
  }
}

export { checkInternetConnection };
