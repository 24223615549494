import request from "superagent";
import { Resource, Method } from "rest-hooks";
import { addAuthQ, addAuthB } from "./util";

const API_URL = "https://jbdlpo4ts9.execute-api.eu-west-2.amazonaws.com/";

export default class EntryResource extends Resource {
  static async fetch<T extends typeof Resource>(
    this: T,
    method: Method = "get",
    url: string,
    body?: Readonly<object | string>
  ) {
    let req = request[method](url);
    req = addAuthQ(req);
    if (body) req = req.send(addAuthB(body));
    return req.then(res => {
      return res.body.taskHours;
    });
  }

  static repeat<T extends typeof Resource>(this: T) {
    return {
      ...this.createShape(),
      options: {
        ...this.getFetchOptions()
      }
    };
  }

  pk() {
    return "singleton";
  }

  static urlRoot = `${API_URL}Dev/user/multitaskhour`;
}
