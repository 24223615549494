import React from "react";
import { IonItem, IonLabel, IonNote } from "@ionic/react";

const TaskItem = ({
  name,
  description
}: {
  name: string;
  description: string;
}) => (
  <IonItem>
    <IonLabel>
      <h1>{name}</h1>
      <IonNote>{description}</IonNote>
    </IonLabel>
  </IonItem>
);

export default TaskItem;
