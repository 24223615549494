import React, { Suspense, useState } from "react";
import { IonApp, IonContent, IonLoading,IonImg } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
// import { NetworkErrorBoundary, NetworkError } from 'rest-hooks';
import EntriesContainer from "./containers/entry";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./style.css"
import clockworkImage from "./theme/images/clockwork_logo.png"

/* Components */
import Tabs from "./components/Tabs";
import NavigationMenu from "./components/NavigationMenu";
import { CacheProvider } from "rest-hooks";

// AWS - Amplify Login Imports
import Amplify from "aws-amplify";
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifyContainer
} from "@aws-amplify/ui-react";
//Function to help run authentication state changes
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import ErrorPage from "./components/ErrorPage";

//Amplify configuration settings that point towards the existing userpool
Amplify.configure({
  aws_project_region: "eu-west-2",
  // aws_cognito_identity_pool_id:
  //   "eu-west-2:664d92a9-e263-458d-8302-9f8aa292ac2c",
  aws_cognito_region: "eu-west-2",
  aws_user_pools_id: "eu-west-2_4Pj8Mxnu0",
  aws_user_pools_web_client_id: "1ou8iavu98g9pp7cbuk5stb60i",
  oauth: {}
});



const App: React.FC = () => {

  class ErrorBoundary extends React.Component <{}, { hasError: boolean }> {
    constructor(props:any) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error:any) {
      console.log("error:", error);
      
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    componentDidCatch(error:any, errorInfo:any) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo,"caught");
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <ErrorPage/>;
      }
  
      return this.props.children; 
    }
  }

  //Loading screen
  const Loading = () => (
    <IonContent class="ion-padding">
      <IonLoading isOpen={true} message={"Loading..."} />
    </IonContent>
  );

  //State to control whats the current authentication situation is i.e is someone logged in
  const [authState, setAuthState] = useState<string>();
  //Function to set authentication as/when it changes
  React.useEffect(() => {
    return onAuthUIStateChange(newAuthState => {
      setAuthState(newAuthState);
    });
  }, []);
  //nested App function to reduce nested component struture see 'html' <App/>
  const App = () => {
    return (
      <React.Fragment>
        <CacheProvider>
          <IonContent class="mainContent">
          <ErrorBoundary>
            <IonReactRouter>
              {NavigationMenu()}
              <EntriesContainer.Provider>      
                <Tabs />    
              </EntriesContainer.Provider>
            </IonReactRouter>
           </ErrorBoundary>
          </IonContent>
        </CacheProvider>
      </React.Fragment>
    );
  };

  const html = (
    //Amplify container is used to centre the signin UI on the page
    <AmplifyContainer className="amplifyContainer">
<IonImg className="clockworkImage" src={clockworkImage}/>
<text className="clockworkTitle">Clockwork</text>
      {/* AmplifyAuthenticator enables customisation of the authentication UI */}
      <AmplifyAuthenticator className="amplifyAuth" usernameAlias="email">
        {/* Adding SignIn allows customisation of the signin form of the UI. Deleting this will default the form to the original(not recommended) */}
        <AmplifySignIn usernameAlias="email" slot="sign-in" hideSignUp={true} />
        {/* statement to render the app if the user is authenticated */}
        {authState === "signedin" && (
          <IonApp>
            <CacheProvider>
                {/* <NetworkErrorBoundary fallbackComponent={ErrorPage}> */}
                {/* <ErrorBoundary> */}
                  <Suspense fallback={<Loading />}>
                    <App />
                 </Suspense>
                {/* </ErrorBoundary> */}
                {/* </NetworkErrorBoundary> */}
            </CacheProvider>
          </IonApp>
        )}
      </AmplifyAuthenticator>
    </AmplifyContainer>
  );
  return html;
};

export default App;
