import React, { FC } from "react";

import { IonList, IonItem, IonIcon } from "@ionic/react";
import { arrowDown, arrowUp } from "ionicons/icons";
import { IFAQQuestion } from "../../pages/Faq";

interface IFAQQuestionProps {
  question: IFAQQuestion;
  setIsSelected: Function;
  isSelected: string;
  key: number;
  index: number;
}

const FaqQuestion: FC<IFAQQuestionProps> = ({
  question,
  setIsSelected,
  isSelected,
  index,
}) => {
  function handleClick() {
    if (isSelected === question.question) {
      return setIsSelected("");
    } else {
      setIsSelected(question.question);
    }
  }
  return (
    <>
      <IonList className="questionWrapper">
        <IonItem
          lines="none"
          className="questionItem"
          button={true}
          onClick={handleClick}
          data-test={`question${index}`}
        >
          <IonIcon
            icon={
              isSelected === question.question
                ? (arrowUp as any)
                : (arrowDown as any)
            }
            slot="end"
          />
          {question.question}
        </IonItem>
        <IonItem
          data-test={`answer${index}`}
          hidden={isSelected !== question.question}
          className="answerItem"
          lines="none"
          button={true}
          onClick={handleClick}
        >
          <p>{question.answer}</p>
        </IonItem>
      </IonList>
    </>
  );
};

export default FaqQuestion;
