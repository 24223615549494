import React, { FC } from "react";
import { IonList, IonListHeader } from "@ionic/react";

import FaqQuestion from "../FaqQuestion";
import { IFAQQuestion, IFAQSection } from "../../pages/Faq";

interface IFAQSectionProps {
  section: IFAQSection;
  setIsSelected: Function;
  isSelected: string;
}

const FaqSection: FC<IFAQSectionProps> = ({
  section,
  setIsSelected,
  isSelected,
}) => {
  return (
    <>
      <IonList className="questionList" data-test={section.name}>
        <IonListHeader className="sectionHeader" id={section.name}>
          {section.name}
        </IonListHeader>
        {section.questions.map(
          (question: IFAQQuestion, questionIndex: number) => {
            return (
              <FaqQuestion
                isSelected={isSelected}
                question={question}
                key={questionIndex}
                index={questionIndex}
                setIsSelected={setIsSelected}
              />
            );
          }
        )}
      </IonList>
    </>
  );
};

export default FaqSection;
