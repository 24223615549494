import { format } from "d3";
import { split } from "lodash";
import { RAD_TO_DEG } from "./constants";

const toSVGRot = (x: number): number => x * RAD_TO_DEG - 90;

const roundQuarter = (time: number | string): string => {  
 const formattedTime = (Math.round(Number(time) * 12) / 12).toFixed(2)

 //split time hours /  mins
 const splitTime = formattedTime.split(".")

 //Correct edge case mins 
if(splitTime[1] === "08"){
  splitTime[1] = "09"
  return splitTime.join(".")
} else if(splitTime[1] === "33"){
  splitTime[1] = "34"
  return splitTime.join(".")
}
  else if(splitTime[1] === "58"){
  splitTime[1] = "59"
  return splitTime.join(".")
} else if(splitTime[1] === "83"){
  splitTime[1] = "84"
  return splitTime.join(".")
} 
 return formattedTime;
}
  

interface currentTimesInterface {
  start: string;
  end: string;
}

const currentTimes: currentTimesInterface = { start: "0", end: "0" };

export { toSVGRot, roundQuarter, currentTimes };

//0.083333333333334
//0.333333333333334
//0.583333333333334
//0.833333333333334
