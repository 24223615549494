const currentUser =
  "CognitoIdentityServiceProvider.1ou8iavu98g9pp7cbuk5stb60i." +
  localStorage.getItem(
    "CognitoIdentityServiceProvider.1ou8iavu98g9pp7cbuk5stb60i.LastAuthUser"
  );
const userTokens = {
  IdToken: localStorage.getItem(`${currentUser}.idToken`),
  AccessToken: localStorage.getItem(`${currentUser}.accessToken`),
  RefreshToken: localStorage.getItem(`${currentUser}.refreshToken`),
};
//Token used in querystring required by the API
const accessToken = userTokens.AccessToken;
//Token used as aan Authorization header required by the API
const authorizationHeader = { Authorization: userTokens.IdToken };
export { userTokens, authorizationHeader, accessToken };
