import { useState, useEffect } from "react";
import moment from "moment";

const useCurrentTime = () => {
  const [timeString, setTimeString] = useState(moment().format("HH:mm"));

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeString(() => moment().format("HH:mm"));
    }, 2000);
    return () => clearInterval(timerId);
  });

  return timeString;
};

export default useCurrentTime;
