import request from "superagent";
import { Resource, Method } from "rest-hooks";
import { addAuthQ, addAuthB } from "./util";
import moment from "moment";

const API_URL = "https://jbdlpo4ts9.execute-api.eu-west-2.amazonaws.com/";



export default class EntryResource extends Resource {
  readonly UserTaskHourCode: string | undefined = undefined;

  static async fetch<T extends typeof Resource>(
    this: T,
    method: Method = "get",
    url: string,
    body?: Readonly<object | string>
  ) {
    let req = request[method](url);
    req = addAuthQ(req);
    if (body) req = req.send(addAuthB(body))

    return req.then(res => {
      if (res.body.items) return res.body.items;
      if (res.body.status === "fail") return [];
      if (!res.body.taskHour) return res.body.items;
      else return res.body.taskHour;
    })
  }

  static update<T extends typeof Resource>(this: T) {
    return {
      ...this.updateShape(),
      options: {
        ...this.getFetchOptions(),
        optimisticUpdate: (params: any, body: any) => ({
          ...body,
          StartTime: moment(body.StartTime).format("HH:mm"),
          EndTime: moment(body.EndTime).format("HH:mm")
        })
      }
    };
  }

  static delete<T extends typeof Resource>(this: T) {
    return {
      ...this.deleteShape(),
      fetch: (params: any, body?: any) =>
        this.fetch("delete", this.url(params), body),
      options: {
        ...this.getFetchOptions()
      }
    };
  }

  pk() {
    return this.UserTaskHourCode;
  }

  static urlRoot = `${API_URL}Dev/user/taskhour`;
}
