import { Auth } from "aws-amplify";

const checkTokenExpiry = async () => {
  try {
    //Get current session tokens from AWS Auth
    const data = await Auth.currentSession();
    //Get the expiry time from the id Token
    const exp = await data.getIdToken().payload.exp;
    //Get the current time and date
    const iatDate: any = new Date();
    //Create a date from the exp data
    const expDate: any = new Date(exp * 1000);
    //Subtract times from each other to find the difference
    const diffTime = Math.abs(iatDate - expDate);
    //If the time is less than 20mins, then
    if (diffTime < 3570000) {
      //refresh the users tokens
      const currentUser = await Auth.currentAuthenticatedUser();
      const currentSession = currentUser.signInUserSession;
      currentUser.refreshSession(currentSession.refreshToken, () => {});
      //else carry on
    }
  } catch (error) {
    if (error) {
      console.log(error);
    }
  }
};

export { checkTokenExpiry };
