const decimalToTimeString = (decimalTime: number): string => {
  // Get just the decimal
  const hour: number = Math.trunc(decimalTime);
  const percentage: number = decimalTime - Math.trunc(decimalTime);

  // percentage * seconds in minute, devided by minutes.
  const minutes: number = Math.trunc((percentage * 3600) / 60);
  const prefixedHour: string =
    hour.toString().length === 1 ? `0${hour}` : String(hour);
  const prefixedMinutes: string =
    minutes.toString().length === 1 ? `0${minutes}` : String(minutes);
  const timeString = `${prefixedHour}:${prefixedMinutes}`;
  return timeString === "24:00" ? "23:59" : timeString;
};

// Similar to the above function but a shorter string
// It removes minutes when time ends at :00 and the inital 0 when time < 10
// i.e. 13:00 becomes 13, while 02:15 is 2:15
const simplifiedDecimalToTimeString = (decimalTime: number): string => {
  // Get just the decimal



  const hour: number = Math.trunc(decimalTime);
  const percentage: number = decimalTime - Math.trunc(decimalTime);

  // percentage * seconds in minute, devided by minutes.
  let minutes: number = Math.trunc((percentage * 3600) / 60);
  if(minutes !% 5){
minutes++
  }
  
  const prefixedMinutes: string =
    minutes.toString().length === 1 ? `0${minutes}` : String(minutes);
  const timeString: string =
    minutes !== 0 ? `${hour}:${prefixedMinutes}` : `${hour}`;
  return timeString;
};

export { decimalToTimeString, simplifiedDecimalToTimeString };
