import { IonContent, IonPage } from "@ionic/react";
import React, { useState, useEffect } from "react";
import moment from "moment";

import { useParams, useHistory } from "react-router-dom";
import { useResource, useFetcher } from "rest-hooks";

import AddTimeButton from "../components/AddTimeButton/";
import TaskMenu from "../components/TaskMenu";
import DaySelector from "../components/DaySelector";
import ClockTask from "../components/Clock/ClockTask";

import spacesForNewTask from "../utils/spacesForNewTask";
import timeStringToFloat from "../utils/timeStringToFloat";
import { decimalToTimeString } from "../utils/timeFormatting";
import EntryResource from "../resources/entries";
import TaskResource from "../resources/tasks";
import Entries from "../containers/entry";

const HomeScreen: React.FC = () => {
  const timeEntriesData = useResource(EntryResource.listShape(), {});
  const tasks = useResource(TaskResource.listShape(), {});

  return <Home timeEntries={timeEntriesData} tasks={tasks} />;
};

const Home = ({ timeEntries, tasks }: any) => {
  const history = useHistory();
  const { date: selectedDate } = useParams();
  const {
    eData: { index, reloadToday, repeatTabEnabled },
    setSelectedIndex,
    setRepeatTabEnabled
  } = Entries.useContainer();
  const [taskIndex, setTaskIndex] = useState(null);
  const [isOverlap, setIsOverlap] = useState(null);
  const [viewSettings, setViewSettings] = useState({
    date: moment(selectedDate)
  });
  const [newTaskStatus, setNewTaskStatus] = useState<string>("");
  const save = useFetcher(EntryResource.update());

  //TEMP FIX TO ERROR BOUNDARY
  const useAsyncError = () => {
 
    //@ts-ignore
    const [_, setError] = React.useState();
 

    return React.useCallback(
      e => {
        setError(() => {
          throw e;
        });
      },
      [setError],
      );
    };

  const throwError =  useAsyncError()
//END OF TEMP FIX

  useEffect(() => {
    setTaskIndex(index);
  }, [index]);

  useEffect(() => {
    setViewSettings({ date: moment(selectedDate) });
  }, [selectedDate, reloadToday]);

  const todayTasks = timeEntries.filter((i: any) => {
    return (
      moment(i.TaskDate).format("YYYY-MM-DD") ===
      moment(viewSettings.date).format("YYYY-MM-DD")
    );
  });

  if (
    viewSettings.date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
  ) {
    const isAvailable = todayTasks.length === 0;
    repeatTabEnabled !== isAvailable && setRepeatTabEnabled(isAvailable);
  }

  useEffect(() => {
    if (newTaskStatus !== "Created") return;

    const newTask = todayTasks.reduce(
      (prev: any, current: any, index: number) =>
        prev[0].UserTaskHourCode > current.UserTaskHourCode
          ? prev
          : [current, index],
      [0, null]
    );

    setTaskIndex(newTask[1]);
    setNewTaskStatus("");
  }, [newTaskStatus, todayTasks]);

  const todayTasksHours: Array<any> = todayTasks.map((task: any) => ({
    ...task,
    start: timeStringToFloat(task.StartTime),
    end: timeStringToFloat(task.EndTime)
  }));

  const unlocked =
    todayTasksHours.length === 0 ||
    todayTasksHours.some((task: any) => task.Status === "Unlocked");

  const handleViewPrevDate = (): void => {
    const newDate = viewSettings.date.clone().subtract(1, "days");
    setTaskIndex(null);
    setViewSettings({ date: newDate });
  };

  const handleViewNextDate = () => {
    const newDate = viewSettings.date.clone().add(1, "days");
    setTaskIndex(null);
    setViewSettings({ date: newDate });
  };

  const selectedTaskHandler = (data: any, i: any) => {
    setTaskIndex(i);
  };

  const handler = async (data: any, isTaskOverlapping: boolean) => {
    if (isTaskOverlapping) {
      setIsOverlap(data);
      return false;
    }

    const taskHour = todayTasksHours[taskIndex || 0];
    taskHour.start = data.start;
    taskHour.end = data.end;
    if (taskHour.UserTaskHourCode) {
      const task = { ...taskHour };
      task.StartTime = `${task.TaskDate} ${decimalToTimeString(task.start)}`;
      task.EndTime = `${task.TaskDate} ${decimalToTimeString(task.end)}`;
      //EDITED CATCH FOR TEMP FIX
      save({ UserTaskHourCode: taskHour.UserTaskHourCode }, task).catch(error=>{
        throwError(new Error("Async Error"))
        }
          );;
    }
  };

  const editHandler = (id: any): void => {
    setSelectedIndex(taskIndex);
    history.push(`/edit/${id}`);
  };

  const handleDeselect = (e: any) => {
    const element = e.target.className.baseVal;
    if (
      !(element === "arcpath" || element === "edit" || element === "edit-icon")
    ) {
      setTaskIndex(null);
    }
  };

  const handleFreeSpaces = () => spacesForNewTask(todayTasksHours);

  return (
    <IonPage>
      <IonContent
        style={{
          textAlign: "center"
        }}
      >
        <DaySelector
          date={viewSettings.date}
          onViewPrevDate={handleViewPrevDate}
          onViewNextDate={handleViewNextDate}
        />
        <ClockTask
          taskData={todayTasksHours}
          selectedTaskIndex={taskIndex}
          selectedTaskHandler={selectedTaskHandler}
          width={400}
          height={400}
          innerRadius={60}
          outerRadius={120}
          clockType={24}
          handler={handler}
          editHandler={editHandler}
          onDeselect={handleDeselect}
          isOverlap={isOverlap}
          loading={newTaskStatus === "Creating"}
        />
        <AddTimeButton
          selectedDate={viewSettings.date}
          getFreeSpaces={handleFreeSpaces}
          disabled={!unlocked || newTaskStatus === "Creating"}
        />
      </IonContent>
      <TaskMenu
        tasks={tasks}
        setNewTaskStatus={setNewTaskStatus}
        setTaskIndex={setTaskIndex}
      />
    </IonPage>
  );
};

export default HomeScreen;
