//Default headers
const defaultHeaders = {
  "Content-Type": "application/json",
};

//BaseURL
const baseURL = `https://jbdlpo4ts9.execute-api.eu-west-2.amazonaws.com`;

const routes = {
  user: {
    get: `${baseURL}/Dev/user`,
  },
  feedback: {
    post: `${baseURL}/Dev/user/feedback`,
  },
  task: {
    get: `${baseURL}/Dev/user/task`,
  },
  taskhour: {
    get: `${baseURL}/Dev/user/taskhour`,
  },
};

export { baseURL, defaultHeaders, routes };
