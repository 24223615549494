import React, { useState, useEffect } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment, { Moment } from "moment";
import { Redirect } from "react-router";

import "./Calendar.css";
import { useFetcher } from "rest-hooks";

import EntryResource from "../../resources/entries";
import Entries from "../../containers/entry";

import { get } from "../../requests/request";
import { routes } from "../../requests/config";

const localizer = momentLocalizer(moment);

const dayAvailable = (start: any) =>
  moment(start) <= moment() ? moment(start) : false;

const createEvents = (entries: any, range: any) => {
  const eventsTotalByDay: any = [];

  for (
    let day = moment(range.start);
    day.isBefore(range.end);
    day.add(1, "days")
  ) {
    const eventsForADay = entries.filter(
      (event: any) => event.TaskDate === day.format("YYYY-MM-DD")
    );

    eventsTotalByDay.push({
      title: `${eventsForADay.length}`,
      start: day.clone(),
      end: day.clone(),
      entities: eventsForADay,
    });
  }

  return eventsTotalByDay;
};

const fetchNewEvents = async (
  range: any,
  setSettings: any,
  fetchEvents: any
) => {
  const newRange = {
    start: moment(range.start),
    end: moment(range.end),
  };

  setSettings((data: any) => ({ ...data, eventsData: [] }));

  const freshEvents = await fetchEvents(
    {
      DateType: "MONTHLY",
      FromDate: newRange.start.format("YYYY-MM-DD"),
      ToDate: newRange.end.format("YYYY-MM-DD"),
    },
    {}
  );

  setSettings((data: any) => ({
    ...data,
    range: newRange,
    entries: freshEvents,
  }));
};

const MyCalendar = ({
  day,
  selectDay,
  loading,
}: {
  day?: Moment | null;
  selectDay?: Function;
  loading?: boolean;
}) => {
  const [settings, setSettings] = useState({
    shouldRedirectToDay: false,
    date: moment().format("YYYY-MM-DD"),
    entries: [],
    eventsData: [],
    range: {
      start: moment()
        .startOf("month")
        .subtract(7, "days"),
      end: moment()
        .endOf("month")
        .add(7, "days"),
    },
  });
  const [disabledGrayNavigation, setDisabledGrayNavigation] = useState(false);

  const entriesC = Entries.useContainer();
  const fetchEvents = useFetcher(EntryResource.listShape());

  useEffect(() => {
    const initialFetchEvents = async () => {

      const freshEvents = await fetchEvents(
        {
          DateType: "MONTHLY",
          FromDate: settings.range.start.format("YYYY-MM-DD"),
          ToDate: settings.range.end.format("YYYY-MM-DD"),
        },
        {}
      );

      const isAvailable = !freshEvents.some(
        (event: any) => event.taskDate === moment().format("YYYY-MM-DD")
      );

      entriesC.eData.repeatTabEnabled !== isAvailable &&
        entriesC.setRepeatTabEnabled(isAvailable);

      if (settings.entries.length !== freshEvents.length) {
        setSettings((data) => ({
          ...data,
          entries: freshEvents,
        }));
      }
    };

    if (settings.entries.length === 0) initialFetchEvents();
  }, [fetchEvents, settings, entriesC]);
  // This useEffect should have an empty array, but esLint check doesn't allow it
  // Line 90 if is a solution to having settings in the dependencies
  // More info: https://github.com/facebook/react/issues/15865

  useEffect(() => {
    if (day && !disabledGrayNavigation) {
      const start = day
        .clone()
        .startOf("month")
        .subtract(7, "days");
      const end = day
        .clone()
        .endOf("month")
        .add(7, "days");
      (settings.range.start < start || settings.range.end > end) &&
        fetchNewEvents({ start, end }, setSettings, fetchEvents);
    }
  }, [day, disabledGrayNavigation, settings.range, fetchEvents, setSettings]);

  useEffect(() => {
    setSettings((data) => ({
      ...data,
      eventsData: createEvents(settings.entries, settings.range),
    }));
  }, [settings.entries, settings.range]);

  return (
    <div className="calendar-container">
      <BigCalendar
        onRangeChange={({ start, end }: any) => {
          selectDay && !loading && selectDay(null);
          !disabledGrayNavigation && setDisabledGrayNavigation(true);

          fetchNewEvents(
            {
              start: moment(start).format("YYYY-MM-DD"),
              end: moment(end).format("YYYY-MM-DD"),
            },
            setSettings,
            fetchEvents
          );
        }}
        localizer={localizer}
        events={settings.eventsData}
        startAccessor="start"
        views={["month"]}
        getNow={() => (day ? day.toDate() : new Date())}
        onSelectEvent={({ start, entities }) => {
          const date = dayAvailable(start);
          if (selectDay && !loading) {
            selectDay(date);
            entriesC.setEntries(entities);
          } else if (date) {
            setSettings((data) => {
              return {
                ...data,
                shouldRedirectToDay: true,
                date: date.format("YYYY-MM-DD"),
              };
            });
          }
        }}
      />
      {settings.shouldRedirectToDay && <Redirect to={`day/${settings.date}`} />}
    </div>
  );
};

export default MyCalendar;
