const timeStringToFloat = (time: string) => {
  const hoursMinutes = time.split(/[.:]/);
  const hours = parseInt(hoursMinutes[0], 10);
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  
  function handleMinutes(mins: number){
    if(mins/60 === 0.16666666666666666){
      return 0.17
    } else if (mins/60 === 0.4166666666666667) {
      return 0.42
    } else if (mins/60 === 0.6666666666666666){
      return 0.67
    } else if (mins/60 === 0.9166666666666666) {
      return 0.92
    }
    return mins / 60
  }

  return hours + handleMinutes(minutes);
};

export default timeStringToFloat;
