import { defaultHeaders } from "./config";
import queryString from "query-string";
import { authorizationHeader } from "./userTokens";
import { checkTokenExpiry } from "./checkTokenExpiry";
import { checkInternetConnection } from "./checkInternetConnection";

//Specify methods that can be used
type APIMethod = "GET" | "POST" | "PUT" | "DELETE";

const APIRequest = (method: APIMethod) => (
  resource: string,
  { options = { headers: {} }, params = {} }: any = {}
) => {
  // Generate the final Options Object
  const requestOptions = {
    method,
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers,
      ...authorizationHeader,
    },
  };
  // Create the URL based on passed in URL + queryParams
  params = { ...params};
  const queryStringParams = { ...params };
  const url = Object.keys(params).length
    ? `${resource}?${queryString.stringify(queryStringParams)}`
    : resource;
  //check internet connection
  return checkInternetConnection().then((data) => {
    if (data >= 200 && data < 300) {
      //Check Cognito Session tokens if they are expired or close to expiring, if they are then get new ones then perform the request
      return checkTokenExpiry().then(() => {
        // Start our request
        return fetch(url, requestOptions).then((response: any) => {
          if (!response.ok) {
            return Promise.reject(response.json()).catch(() => {
              throw new Error(response.status);
            });
          } else return Promise.resolve(response.json());
        });
      });
    }
  });
};

export const get = APIRequest("GET");
export const post = APIRequest("POST");
