import React from "react";

import { IonButton, IonIcon, IonText } from "@ionic/react";
import { arrowBack, arrowForward } from "ionicons/icons";
import moment from "moment";

import "./DaySelector.css";

const isToday = (date: moment.Moment) =>
  date.isSame(moment(), "d") ? "today" : "";

const isYesterday = (date: moment.Moment) =>
  date.isSame(moment().subtract(1, "day"), "d") ? "yesterday" : "";

const DaySelector = ({
  date,
  onViewPrevDate,
  onViewNextDate
}: {
  date: moment.Moment;
  onViewPrevDate: () => void;
  onViewNextDate: () => void;
}) => (
  <div className="DateSelector">
    <IonButton fill="clear" onClick={onViewPrevDate}>
      <IonIcon
        color="secondary"
        slot="icon-only"
        ios={arrowBack.ios}
        md={arrowBack.md}
      />
    </IonButton>
    <IonText>
      <p className={`day ${isToday} ${isYesterday}`}>
        {date.calendar(undefined, {
          lastWeek: "[Last] dddd, MMMM Do",
          lastDay: "[Yesterday]",
          sameDay: "[Today]",
          nextDay: "[Tomorrow]",
          nextWeek: "[Next] dddd, MMMM Do",
          sameElse: `dddd, ${
            date.isSame(moment(), "year") ? "MMM Do" : "MMM Do YYYY"
          }`
        })}
      </p>
    </IonText>
    <IonButton fill="clear" onClick={onViewNextDate}>
      <IonIcon
        color="secondary"
        slot="icon-only"
        ios={arrowForward.ios}
        md={arrowForward.md}
      />
    </IonButton>
  </div>
);

export default DaySelector;
