import React, { Suspense } from "react";
import { Route, Redirect } from "react-router";
import { IonRouterOutlet, IonLoading, IonContent } from "@ionic/react";

import Home from "../pages/Home";
import About from "../pages/About";
import Feedback from "../pages/Feedback";
import Faq from "../pages/Faq";
import Tasks from "../pages/Tasks";
import Settings from "../pages/Settings";
import Calendar from "../pages/Calendar";
import Repeat from "../pages/Repeat";
import Edit from "../pages/Edit";

import moment from "moment";

export const Loading = () => (
  <IonContent>
    <IonLoading isOpen={true} message="Loading ..." />
  </IonContent>
);

const Routes = () => (
  <IonRouterOutlet>
    <Route path="/day/:date" exact={true}>
      <Suspense fallback={<Loading />}>
        <Home />
      </Suspense>
    </Route>
    <Route path="/about" exact={true}>
      <About />
    </Route>
    <Route path="/tasks" exact={true}>
      <Tasks />
    </Route>
    <Route path="/feedback" exact={true}>
      <Feedback />
    </Route>
    <Route path="/faq" exact={true}>
      <Faq />
    </Route>
    <Route path="/settings" exact={true}>
      <Settings />
    </Route>
    <Route path="/calendar" exact={true}>
      <Calendar />
    </Route>
    <Route path="/repeat" exact={true}>
      <Repeat />
    </Route>
    <Route path="/edit/:UserTaskHourCode" exact={true}>
      <Edit />
    </Route>
    <Route
      exact
      path="/"
      render={() => <Redirect to={`/day/${moment().format("YYYY-MM-DD")}`} />}
    />
  </IonRouterOutlet>
);

export default Routes;
