import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonCheckbox,
  IonTextarea,
  IonButton,
  IonToast,
  IonLoading,
} from "@ionic/react";
import React, { useState } from "react";

import { post } from "../requests/request";
import { routes } from "../requests/config";

interface ToastState {
  showFailToast: boolean;
  showSuccessToast: boolean;
}

export async function handleFetch(
  checked: boolean = true,
  text: string = "no text provided"
) {
  const response = await post(routes.feedback.post, {
    options: {
      body: JSON.stringify({
        type: checked ? "Suggestion" : "Error/Bug",
        message: text,
      }),
    },
  });
  if (response?.status >= 200 && response?.status <= 299) {
    return {
      payload: response,
      success: true,
      fail: false,
      checked: checked,
      text: text,
    };
  } else {
    return {
      payload: response,
      success: false,
      checked: checked,
      text: text,
    };
  }
}

// export async function handleFetch(
//   checked: boolean = true,
//   text: string = "no text provided"
// ) {
//   const res = await fetch(
//     "https://jbdlpo4ts9.execute-api.eu-west-2.amazonaws.com/Dev/user/feedback",
//     {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         type: checked ? "Suggestion" : "Error/Bug",
//         message: text,
//       }),
//     }
//   );
//   if (res?.status >= 200 && res?.status <= 299) {
//     return {
//       payload: res,
//       success: true,
//       fail: false,
//       checked: checked,
//       text: text,
//     };
//   } else {
//     return {
//       payload: res,
//       success: false,
//       checked: checked,
//       text: text,
//     };
//   }
// }

const Feedback: React.FC = () => {
  const [text, setText] = useState<string>();
  const [checked, setChecked] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [toastState, setToastState] = useState<ToastState>({
    showFailToast: false,
    showSuccessToast: false,
  });

  const sendFeedback = async () => {
    setLoading(true);
    try {
      const res = await handleFetch(checked, text);
      setLoading(false);
      setToastState({
        ...toastState,
        showSuccessToast: res.success,
        showFailToast: !res.success,
      });
      setText(res.success ? "" : text);
    } catch (error) {
      setLoading(false);
      setToastState({
        ...toastState,
        showFailToast: true,
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle className="title">Feedback</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItemDivider>Select Feedback Type</IonItemDivider>
          <IonItem>
            <IonLabel>Suggestions</IonLabel>
            <IonCheckbox
              data-test="suggestions"
              color="primary"
              slot="start"
              checked={checked}
              onClick={() => {
                setChecked(!checked);
              }}
            ></IonCheckbox>
          </IonItem>
          <IonItem>
            <IonCheckbox
              data-test="issue"
              color="danger"
              checked={!checked}
              slot="start"
              onClick={() => {
                setChecked(!checked);
              }}
            ></IonCheckbox>
            <IonLabel>Issue</IonLabel>
          </IonItem>
          <IonItemDivider>Comments</IonItemDivider>
          <IonItem>
            <IonTextarea
              placeholder="Please enter your comments here"
              value={text}
              onIonChange={(e) => setText(e.detail.value!)}
              autoGrow={true}
              required={true}
            ></IonTextarea>
          </IonItem>
        </IonList>
        <IonButton
          data-test="submitButton"
          expand="block"
          color="primary"
          onClick={sendFeedback}
        >
          Submit
        </IonButton>

        <IonLoading isOpen={loading} message={"Submitting Feedback"} />
        <IonToast
          data-test="successToast"
          isOpen={toastState.showSuccessToast}
          onDidDismiss={() =>
            setToastState({ ...toastState, showSuccessToast: false })
          }
          message="Thank you for submitting your feedback"
          position="bottom"
          color="success"
          duration={5000}
          buttons={[
            {
              text: "Close",
              role: "cancel",
              handler: () => {},
            },
          ]}
        />
        <IonToast
          data-test="failToast"
          isOpen={toastState.showFailToast}
          onDidDismiss={() =>
            setToastState({ ...toastState, showFailToast: false })
          }
          message="There was an error submitting your feedback, please try again later"
          position="bottom"
          color="danger"
          duration={5000}
          buttons={[
            {
              text: "Close",
              role: "cancel",
              handler: () => {},
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Feedback;
