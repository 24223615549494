import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";

import useCurrentTime from "../../hooks/useCurrentTime";
import { simplifiedDecimalToTimeString } from "../../utils/timeFormatting";
import timeStringToFloat from "../../utils/timeStringToFloat";
import { addDragHandlers, updateTask } from "./utilities/dragFunctions";
import { toSVGRot, currentTimes } from "./utilities/util";
import { getPeriod, timeToTheta } from "./utilities/timeFunctions";

import "./ClockTask.css";
import SVGLoader from "./SVGLoader";

// to comment out
window.d3 = d3;

const ClockTask = (props: any): React.ReactElement => {
  const ref: any = useRef(null);
  const arrowTime: string = useCurrentTime();

  // Clock status info
  interface stateInterface {
    icon: string;
    action: string;
    taskName: string;
    time: number;
    className: string;
    iconDy: number;
  }
  const [statusPanel, setStatusPanel] = useState<stateInterface>({
    icon: "access_time",
    action: "",
    taskName: "",
    time: 0,
    className: "",
    iconDy: -1.6
  });

  let timeMod: number = props.clockType === "24" ? 24 : 12;

  const createArc: any = d3
    .arc()
    .innerRadius(props.innerRadius)
    .outerRadius(props.outerRadius);

  const arcFromTimes = (d: any, i: number) => {
    if (i === props.selectedTaskIndex && d.Status !== "Locked") {
      return "";
    }
    const pathData = {
      startAngle: timeToTheta(d.start, props),
      endAngle: timeToTheta(d.end, props)
    };
    // @ts-ignore
    return createArc(pathData);
  };

  useEffect(() => {
    // Add clock hours
    timeMod = props.clockType === 24 ? 24 : 12;
    const svg = d3.select(ref.current);
    const radials = d3.range(timeMod).map(d => (d * Math.PI * 2) / timeMod);
    const radialG = svg.select(".radials");
    radialG
      .selectAll(".radial")
      .data(radials)
      .join(
        // @ts-ignore
        enter => {
          enter
            .append("line")
            .attr("class", "radial")
            .attr("x2", (d: number) => Math.cos(d) * props.outerRadius)
            .attr("y2", (d: number) => Math.sin(d) * props.outerRadius);
        },
        update => {
          update
            .attr("x2", (d: number) => Math.cos(d) * props.outerRadius)
            .attr("y2", (d: number) => Math.sin(d) * props.outerRadius);
        },
        exit => {
          exit.remove();
        }
      );
    // Deselect handler
    svg.on("click", () => {
      svg.selectAll("text").on(".drag", null);
      props.onDeselect(d3.event);
    });
  }, []);

  useEffect(() => {
    // Add tasks to the clock
    const svg = d3.select(ref.current);
    const arcs = svg.select(".arcs");
    let color = ""
    arcs
    .selectAll("path")
    .data(props.taskData)
    .join("path")
    .attr("class", "arcpath")
    .attr("d", arcFromTimes)
    .on("click", props.selectedTaskHandler);
    arcs.selectAll("path").each(function(d: any, i: number) {
      //task color
props.taskData[i].TaskColor ? color = props.taskData[i].TaskColor : 
      color = Math.floor(
        Math.abs(Math.sin(d.UserTaskHourCode) * 16777215) % 16777215
      ).toString(16);
      d3.select(this).attr("style", `fill: ${color}`);
    });
    const radialG = svg.select(".radials");
    let labels = radialG
      .selectAll("text")
      .data(props.taskData, (d: any) => d.UserTaskHourCode);

    labels
      .enter()
      .append("text")
      .attr("class", (t: any) => t.Status !== "Locked" && "draggable-text")
      .attr("font-family", "Material Icons")
      .attr("id", function(d: any, i: number) {
        return `icon_${d.UserTaskHourCode}`;
      })
      .on("click", (e: any, i: number) => {
        d3.event.stopPropagation();
        props.selectedTaskHandler(e, i);
      })
      .attr("transform", function(d: any, i: number) {
        const iconRadius: number = props.outerRadius - 30;
        const pathData = {
          startAngle: timeToTheta(d.start, props),
          endAngle: timeToTheta(d.end, props)
        };
        const iconElements = document.getElementsByClassName("draggable-text")
//Checks initial difference between end and start time and decides whether or not to render the task icon
  for (let index = 0; index < props.taskData.length; index++) {
if(props.taskData[index].Diff < 60){
  iconElements[index].setAttribute("class", "hidden draggable-text")   
} else iconElements[index].setAttribute("class", "draggable-text") 
  }
//------- 
        const angle: number =
          pathData.startAngle + (pathData.endAngle - pathData.startAngle) / 2;


        return `translate(${iconRadius * Math.sin(angle) -
          8} ${-iconRadius * Math.cos(angle) + 8})`;
      })
      .text(function(d: any) {
        return (d.Status === "Locked" && "lock") || d.TaskIcon || "event_note";
      });
    addDragHandlers(ref, props);
    labels.exit().remove();
  }, [props.taskData]);






  useEffect(() => {
    // A task has been selected / unselcted
    const svg = d3.select(ref.current);
    if (props.selectedTaskIndex !== null) {
      svg.select(".handles").style("display", "initial");
      const task = props.taskData[props.selectedTaskIndex];
      const hours: number = task.end - task.start;
        currentTimes.start = task.start;
        currentTimes.end = task.end;  



//Checks edited difference between end and start time and decides whether or not to render the task icon
  const iconElements = document.getElementsByClassName("draggable-text")
  for (let index = 0; index < props.taskData.length; index++) {  
if(props.taskData[index].Diff < 60){
  iconElements[index].setAttribute("class", "hidden draggable-text")   
} else iconElements[index].setAttribute("class", "draggable-text") 
  }
//--------
      if (task.Status === "Locked") {
        svg.selectAll(".editable").style("display", "none");
        // Set clock status
        setStatusPanel({
          icon: "lock",
          action: "Locked",
          taskName: task.TaskName,
          time: hours,
          className: "selected",
          iconDy: -2.4
        });
      } else {
        svg.selectAll(".editable").style("display", "initial");
        // Set clock status
        setStatusPanel({
          icon: "edit",
          action: "Editing",
          taskName: task.TaskName,
          time: hours,
          className: "selected",
          iconDy: -2.4
        });
        svg
          .select("circle.edit")
          .on("click", () => props.editHandler(task.UserTaskHourCode));
        svg
          .select("path.edit-icon")
          .on("click", () => props.editHandler(task.UserTaskHourCode));
      }
      updateTask(ref, props, task.Status === "Locked");
    } else {
      // Hide editable arc
      svg.select(".handles").style("display", "none");
      svg.select(".editable").style("display", "none");
      // Set clock status
      const hours: number = props.taskData.reduce(
        (acc: number, t: any) => acc + t.end - t.start,
        0
      );
      setStatusPanel({
        icon: "access_time",
        action: "",
        taskName: "",
        time: hours,
        className: "",
        iconDy: -1.6
      });
    }
  }, [props.taskData, props.clockType, props.selectedTaskIndex]);

  useEffect(() => {}, [props.isOverlapping]);

  return (
    <svg
      className="ClockTask"
      ref={ref}
      viewBox="65 40 270 270"
      width={props.width}
      height={props.height}
    >
      <g transform={`translate(${props.width / 2} ${props.height / 2})`}>
        <g className="dais">
          <circle r={`${props.outerRadius}`}></circle>
        </g>
        <g className="arcs">
          <path className="arcpath"></path>
        </g>
        <g className="arc draggable editable">
          <path className="arcpath edit-arcpath"></path>
        </g>
        <g className="radials"></g>
        <defs>
          <marker
            id="arrowhead"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="1.5"
            orient="auto"
          >
            <polygon points="0 0, 3 1.5, 0 3" fill="#FD3350" />
          </marker>
        </defs>
        <g
          className="pointer"
          transform={`rotate(${toSVGRot(
            timeToTheta(timeStringToFloat(arrowTime), props)
          )})`}
        >
          <line
            x1="0"
            y1="0"
            x2={props.outerRadius - 22}
            y2="0"
            stroke="#000"
            strokeWidth="2"
            markerEnd="url(#arrowhead)"
          />
          <text
            textAnchor="middle"
            x={(props.outerRadius + props.innerRadius) * 0.43}
            y={-5}
          >
            {arrowTime} {getPeriod(arrowTime)}
          </text>
        </g>
        <g className="mask">
          <circle r={`${props.innerRadius}`}></circle>
          {props.loading ? (
            <>
              //@ts-ignore
              <SVGLoader textAnchor="middle" dominantBaseline="central" />
              <text x="-26" y="0.5em" className="status-loading">
                Loading...
              </text>
            </>
          ) : (
            <text
              className={statusPanel.className}
              textAnchor="middle"
              dominantBaseline="central"
            >
              <tspan
                fontFamily="Material Icons"
                x="0"
                dy={`${statusPanel.iconDy}em`}
                className="status-icon"
                textAnchor="middle"
                alignmentBaseline="central"
              >
                {statusPanel.icon}
              </tspan>
              <tspan x="0" dy="3.2em" className="status-action">
                {statusPanel.action}
              </tspan>
              <tspan x="0" dy="1.4em" className="status-task-name">
                {statusPanel.taskName}
              </tspan>
              <tspan x="0" dy="2.6em" className="status-time">
                {simplifiedDecimalToTimeString(statusPanel.time)} hours
              </tspan>
              <tspan x="0" dy="1.7em" className="logged-text">
                logged
              </tspan>
            </text>
          )}
        </g>
        <g className="handles">
          <g className="edit editable">
            <circle r="20" className="edit"></circle>
            <path
              className="edit-icon"
              d="M7 14.94l6.06-6.06l2.06 2.06L9.06 17H7v-2.06M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m4.7-10.65l-1 1l-2.05-2.05l1-1c.21-.22.56-.22.77 0l1.28 1.28c.22.21.22.56 0 .77M12 2a10 10 0 0 1 10 10a10 10 0 0 1-10 10A10 10 0 0 1 2 12A10 10 0 0 1 12 2"
              fill="#626262"
            />
          </g>
          <g className="earlier">
            <circle r="20" className="draggable earlier editable"></circle>
          </g>

          <g className="earlier-time">
            <text className="hour" textAnchor="middle" y="0px"></text>
            <text className="period" textAnchor="middle" y="7px"></text>
          </g>

          <g className="later">
            <circle r="20" className="draggable later editable"></circle>
          </g>
          <g className="later-time">
            <text className="hour" textAnchor="middle" y="0px"></text>
            <text className="period" textAnchor="middle" y="7px"></text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ClockTask;
