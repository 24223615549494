import React, { useRef, useState } from "react";
import {
  IonAlert,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel
} from "@ionic/react";
import { menu, calendar, repeat, clock } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";

import Routes from "./Routes";
import Entries from "../containers/entry";
import toggleIonicMenu from "../utils/toggleIonicMenu";

const Tabs = () => {
  const entries = Entries.useContainer();
  const history = useHistory();
  const IonTabsRef = useRef<any>(null);
  const HomeRef = useRef<any>(null);
  const RepeatRef = useRef<any>(null);
  const CalendarRef = useRef<any>(null);

  const [showAlert, setShowAlert] = useState(false);

  return (
    <>
      <IonTabs ref={IonTabsRef}>
        {Routes()}

        <IonTabBar slot="bottom">
          <IonTabButton
            tab="menu"
            onClick={e => {
              e.preventDefault();
              toggleIonicMenu("#navigation-menu");
            }}
          >
            <IonIcon ios={menu.ios} md={menu.md} />
            <IonLabel>Menu</IonLabel>
          </IonTabButton>

          <IonTabButton tab="calendar" ref={CalendarRef} href="/calendar">
            <IonIcon ios={calendar.ios} md={calendar.md} />
            <IonLabel>Calendar</IonLabel>
          </IonTabButton>

          <IonTabButton
            tab="repeat"
            ref={RepeatRef}
            onClick={e => {
              if (entries.eData.repeatTabEnabled) {
                history.replace("/repeat");
                RepeatRef.current.selected = true;

                //Since repeat doesn't have an href it has to be selected manually
                switch (IonTabsRef.current.tabBarRef.current.state.activeTab) {
                  case "home":
                    HomeRef.current.selected = false;
                    break;
                  case "calendar":
                    CalendarRef.current.selected = false;
                    break;
                  case undefined:
                    HomeRef.current.selected = false;
                    CalendarRef.current.selected = false;
                }
              } else {
                setShowAlert(true);
              }
            }}
          >
            <IonIcon ios={repeat.ios} md={repeat.md} />
            <IonLabel>Repeat</IonLabel>
          </IonTabButton>

          <IonTabButton
            tab="home"
            ref={HomeRef}
            href={`/day/${moment().format("YYYY-MM-DD")}`}
            onClick={() => entries.ReloadToday()}
          >
            <IonIcon ios={clock.ios} md={clock.md} />
            <IonLabel>Today</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={"Whoops"}
        subHeader={"Today has registered tasks."}
        message={"Please ensure today is empty before trying to repeat a day."}
        buttons={["Ok"]}
      />
    </>
  );
};

export default Tabs;
