import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { get } from "../requests/request";
import { routes } from "../requests/config";
import TaskItem from "../components/TaskItem";
import {accessToken} from "../requests/userTokens"

const Tasks: React.FC = () => {
  const [tasks, setTasks] = useState([]);
  const [fetchError, setFetchError] = useState(false);
  useEffect(() => {
    get(routes.task.get, {params: {AccessToken: accessToken}})
      .then((data) => {
        if (data.items) {
          setTasks(data.items);
          return { success: true, data: data };
        } else setFetchError(true);
      })
      .catch((error) => {
        return {
          success: false,
          error,
        };
      });
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Tasks</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {tasks.map((task: any) => (
            <TaskItem
              name={task.TaskName}
              description={task.TaskDescription}
              key={task.TaskCode}
            />
          ))}
        </IonList>
        <IonToast
          isOpen={fetchError}
          onDidDismiss={() => setFetchError(false)}
          message="Cannot detect an internet connection, please reconnect and try again"
          duration={10000}
          color="danger"
          buttons={[
            {
              side: "end",
              text: "close",
              handler: () => {
                setFetchError(false);
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Tasks;
