const addAuthQ = (request: any) => {
  const currentUser =
    "CognitoIdentityServiceProvider.1ou8iavu98g9pp7cbuk5stb60i." +
    localStorage.getItem(
      "CognitoIdentityServiceProvider.1ou8iavu98g9pp7cbuk5stb60i.LastAuthUser"
    );
  const data = {
    IdToken: localStorage.getItem(currentUser + ".idToken"),
    AccessToken: localStorage.getItem(currentUser + ".accessToken"),
    RefreshToken: localStorage.getItem(currentUser + ".refreshToken")
  };

  const { IdToken, AccessToken } = data;
  try {
    request.query({ AccessToken: AccessToken }).set({ Authorization: IdToken });
  } catch (err) {
    console.warn(err);
    return false;
  }
  return request;
};

const handleError = (data: any) => {
  const isError =
    (data.error && data.error.message) ||
    (data.status === "fail" && data.message) ||
    data.errorMessage;

  if (isError) {
    console.error(data);
  }

  return isError;
};

const addAuthB = (body: any) => {
  const currentUser =
    "CognitoIdentityServiceProvider.1ou8iavu98g9pp7cbuk5stb60i." +
    localStorage.getItem(
      "CognitoIdentityServiceProvider.1ou8iavu98g9pp7cbuk5stb60i.LastAuthUser"
    );
  const data = {
    IdToken: localStorage.getItem(currentUser + ".idToken"),
    AccessToken: localStorage.getItem(currentUser + ".accessToken"),
    RefreshToken: localStorage.getItem(currentUser + ".refreshToken")
  };
  return { ...body, AccessToken: data.AccessToken };
};

export { addAuthQ, handleError, addAuthB };
