import React from "react";
import { IonContent, IonPage } from "@ionic/react";

import CalendarComponent from "../components/Calendar";

const Calendar: React.FC = () => {
  return (
    <IonPage>
      <IonContent className="ion-padding">
        <CalendarComponent />
      </IonContent>
    </IonPage>
  );
};

export default Calendar;
