import { useState } from "react";
import { createContainer } from "unstated-next";

const initialState: any = {
  entries: [],
  start: 0,
  end: 0,
  date: new Date(),
  colors: [],
  index: null,
  repeatTabEnabled: false,
  comments: ""
};

const useTimeEntries = (state = initialState) => {
  let [eData, setData] = useState(state);

  const ReloadToday = () => {
    setData((data: any) => {
      return { ...data, reloadToday: !data.reloadToday };
    });
  };

  const setEntries = (entries: any) => {
    setData((data: any) => ({ ...data, entries: entries }));
  };

  const setRepeatTabEnabled = (enabled: boolean) => {
    setData((data: any) => ({ ...data, repeatTabEnabled: enabled }));
  };

  const setTimeForNewTimeEntry = (date: any) => {
    setData((data: any) => {
      return { ...data, date: date };
    });
  };

  const setSelectedIndex = (index: number | null) => {
    setData((data: any) => {
      return { ...data, index };
    });
  };

  return {
    eData,
    ReloadToday,
    setEntries,
    setTimeForNewTimeEntry,
    setSelectedIndex,
    setRepeatTabEnabled
  };
};

const Entries = createContainer(useTimeEntries);

export default Entries;
