import React, {useState} from "react"
import{IonContent, IonButton,IonItem}from '@ionic/react'
import {checkInternetConnection} from "../../requests/checkInternetConnection"
import "./ErrorPage.css"

const ErrorPage = () => {
    const [showError, setShowError] = useState<boolean>(false)

function handleClick(){
checkInternetConnection().then((data: any)=>{
    if(data === 500){
        setShowError(true)
        setTimeout(()=>{
            setShowError(false)
        },3000)
    } else if (data === 200){
window.location.reload()
    }
})
}
function pollConnection(){
    checkInternetConnection().then((data: any)=>{
        if(data === 500){
            //respond to connection error here
        } else if (data === 200){
    window.location.reload()
        }
    })
}
setTimeout(pollConnection, 5000)
// @ts-ignore
clearTimeout(pollConnection)





return(<IonContent className="errorContainer">
    <IonItem className="errorHeader"><h1>There was a network error whilst processing your request</h1></IonItem> 
    {/* <IonImg className="errorImage" src={require("./download.png")} />   */}
    <IonItem className="errorImage" lines="none"></IonItem>
                {
                    showError ? 
                    <IonItem  lines="none" color="danger" className="errorDisplay" type="button" onClick={()=>setShowError(false)}>
                    Connection Error: Please try again later
                    </IonItem > : <div className="placeholder"></div>
                }

                <IonButton  className="errorReloadButton" size="large" onClick={handleClick} expand="block">Reload Page</IonButton>
                
        </IonContent>)

}

export default ErrorPage