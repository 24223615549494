import React, { useState, FC } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
} from "@ionic/react";

import faqData from "../faqData/index.json";
import FaqSection from "../components/FaqSection";
import "./Faq.css";

export interface IFAQQuestion {
  question: string;
  answer: string;
}
export interface IFAQSection {
  name: string;
  questions: IFAQQuestion[];
}
export interface IFAQ {
  sections: IFAQSection[];
}

const Faq: FC = () => {
  // const [searchQuery, setSearchQuery] = useState<string>();
  const [isSelected, setIsSelected] = useState<string>("");
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle className="title">Frequently Asked Questions</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {/* NOT IN USE - awaiting */}
          {/* <IonItem lines="none">
            <IonSearchbar
              className="searchQuery"
              type="text"
              value={searchQuery}
              placeholder="Begin Search"
              onIonChange={(e) => setSearchQuery(e.detail.value)}
            ></IonSearchbar>
          </IonItem> */}
          {/*NOT IN USE - awaiting method}
          {/* <IonItem>Quick Links</IonItem> */}
          {/* {faqData.sections.map(
            (section: IFAQSection, sectionIndex: number) => {
              return (
                <IonItem
                  lines="none"
                  className="sectionQuickLinks"
                  key={sectionIndex}
                  routerLink={`#${section.name}`}
                >
                  {section.name}
                </IonItem>
              );
            }
          )} */}
        </IonList>
        {faqData.sections.map((section: IFAQSection, sectionIndex: number) => {
          return (
            <FaqSection
              setIsSelected={setIsSelected}
              isSelected={isSelected}
              section={section}
              key={sectionIndex}
            />
          );
        })}
      </IonContent>
    </IonPage>
  );
};

export default Faq;
