import { TWO_PI } from "./constants";

const getPeriod = (time: string): string =>
  parseInt(time) >= 12 ? "PM" : "AM";

const thetaToTime = (theta: number, props: any): number => {
  if (props.clockType === 24) {
    return (theta / TWO_PI) * 24;
  } else {
    return (theta / TWO_PI) * 12;
  }
};

const timeToTheta = (time: number | string, props: any): number => {
  time = Number(time);
  if (props.clockType === 24) {
    return (TWO_PI * time) / 24;
  } else {
    if (time === 24) return TWO_PI * 2;
    let theta = (TWO_PI * (time % 12)) / 12;
    if (time > 12) theta += TWO_PI;
    return theta;
  }
};

export { getPeriod, thetaToTime, timeToTheta };
