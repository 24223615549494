import timeStringToFloat from "./timeStringToFloat";
import moment from "moment";

/**
 * Given a date and an array of spaces it returns the date modified with the hour closest to the one given
 * It priorities earlier hours.
 * @DEFAULT_TIME {float} is how long tasks are by default, measured in hours.
 * @param {Moment} date - current date.
 * @param {[number, number][]} freeSpaces - an array of freeGaps to compare. Cant be empty.
 * @return {Moment} A new date with a free hour closest to one given.
 */

/**
 * Current space starts before current hour?
 *   ✓ Current space ends after current hour?
 *     ✓ Current hour is available
 *     ✘ Space does not include currentHour, saving where it ends as a possible candidate
 *   ✘ Have I checked for any candidates?
 *     ✓ I have not, clock is full after past current hour. Saving start of the gap + 1 hour
 *     ✘ I had, previous value is the best candidate.
 */

const DEFAULT_TIME: number = 1;

const getClosestHourAvailable = (
  date: moment.Moment,
  freeSpaces: Array<any>
): moment.Moment => {
  const currentHour: number = timeStringToFloat(date.format("HH:mm"));
  const freeHour: number = freeSpaces.reduce((prev, current, index) => {
    if (current[0] + DEFAULT_TIME <= currentHour) {
      if (current[1] >= currentHour) {
        return currentHour;
      } else {
        return current[1];
      }
    } else if (index === 0) {
      return current[0] + DEFAULT_TIME;
    } else {
      return prev;
    }
  }, currentHour);

  return moment(date)
    .hours(0)
    .minutes(freeHour * 60);
};

export default getClosestHourAvailable;
