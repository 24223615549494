import React, { useState } from "react";
import { IonAlert, IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { add } from "ionicons/icons";
import toggleIonicMenu from "../../utils/toggleIonicMenu";
import getClosestHourAvailable from "../../utils/getClosestHourAvailable";
import Entries from "../../containers/entry";
import moment from "moment";

import "./AddTimeButton.css";

interface Props {
  selectedDate: any;
  getFreeSpaces: any;
  disabled: boolean;
}

const AddTimeButton = ({ selectedDate, getFreeSpaces, disabled }: Props) => {
  const entries = Entries.useContainer();
  const [showAlert, setShowAlert] = useState(false);

  return (
    <>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton
          onClick={() => {
            const freeSpaces = getFreeSpaces();
            if (freeSpaces.length) {
              const now = moment();
              const newTaskDate = getClosestHourAvailable(
                selectedDate.hours(now.hour()).minutes(now.minutes()),
                freeSpaces
              );
              entries.setTimeForNewTimeEntry(newTaskDate);
              toggleIonicMenu("#task-menu");
            } else {
              setShowAlert(true);
            }
          }}
          disabled={disabled}
        >
          <IonIcon ios={add.ios} md={add.md} />
        </IonFabButton>
      </IonFab>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={"Whoops"}
        subHeader={"Not enough space"}
        message={
          "Please ensure you have an hour gap free before adding more tasks."
        }
        buttons={["Ok"]}
      />
    </>
  );
};

export default AddTimeButton;
