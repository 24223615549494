import { Resource, Method } from "rest-hooks";
import { addAuthQ } from "./util";
import request from "superagent";

const API_URL = "https://jbdlpo4ts9.execute-api.eu-west-2.amazonaws.com/";

export default class TasksResource extends Resource {
  readonly TaskCode: string = "1";
  readonly TaskName: string = "This is a task name";
  readonly TaskDescription: string = "This is a description";

  pk() {
    return this.TaskCode;
  }

  static async fetch<T extends typeof Resource>(
    this: T,
    method: Method = "get",
    url: string,
    body?: Readonly<object | string>
  ) {
    let req = request[method](url).on("error", () => {});
    req = addAuthQ(req);
    if (body) req = req.send(body);
    return req.then(res => {
      return res.body.items;
    });
  }

  static urlRoot = `${API_URL}Dev/user/task`;
}
