import React from "react";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonFooter,
} from "@ionic/react";
import {
  informationCircleOutline,
  checkboxOutline,
  cog,
  person,
  chatboxes,
  helpCircleOutline,
} from "ionicons/icons";

import "./NavigationMenu.css";

import toggleIonicMenu from "../../utils/toggleIonicMenu";

const toggleMenuFactory = () => toggleIonicMenu("#navigation-menu");

const NavigationMenu = () => {
  const logoutUser = () => {
    //ADD something here to reset URL from day
    localStorage.clear();
    window.location.reload();
  };

  return (
    <IonMenu
      swipeGesture
      side="start"
      //contentId="navigation-menu"
      id="navigation-menu"
      menuId="navigation-menu"
      type="overlay"
    >
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Clockwork</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem routerLink="/about" onClick={toggleMenuFactory}>
            <IonIcon
              ios={informationCircleOutline.ios}
              md={informationCircleOutline.md}
            />
            <IonLabel>About</IonLabel>
          </IonItem>
          <IonItem
            data-test="tasks"
            routerLink="/tasks"
            onClick={toggleMenuFactory}
          >
            <IonIcon ios={checkboxOutline.ios} md={checkboxOutline.md} />{" "}
            <IonLabel>Tasks</IonLabel>
          </IonItem>
          {/* <IonItem
            data-test="settings"
            routerLink="/settings"
            onClick={toggleMenuFactory}
          >
            <IonIcon ios={cog.ios} md={cog.md} />
            <IonLabel>Settings</IonLabel>
          </IonItem> */}
          <IonItem
            data-test="feedback"
            routerLink="/feedback"
            onClick={toggleMenuFactory}
          >
            <IonIcon ios={chatboxes.ios} md={chatboxes.md} />
            <IonLabel>Feedback</IonLabel>
          </IonItem>
          <IonItem
            data-test="faq"
            routerLink="/faq"
            onClick={toggleMenuFactory}
          >
            <IonIcon ios={helpCircleOutline.ios} md={helpCircleOutline.md} />
            <IonLabel>FAQ</IonLabel>
          </IonItem>
        </IonList>
        <IonFooter>
          <IonItem data-test="logout" routerLink="/" onClick={logoutUser}>
            <IonIcon ios={person.ios} md={person.md} />
            <IonLabel>Logout</IonLabel>
          </IonItem>
        </IonFooter>
      </IonContent>
    </IonMenu>
  );
};

export default NavigationMenu;
