import React, { useState } from "react";
import { IonText, IonContent, IonPage, IonRow, IonButton } from "@ionic/react";

import CalendarComponent from "../components/Calendar";
import moment, { Moment } from "moment";
import Entries from "../containers/entry";
import { useHistory } from "react-router-dom";
import { useFetcher } from "rest-hooks";

import RepeatResource from "../resources/repeat";
import EntryResource from "../resources/entries";

const Repeat: React.FC = () => {
  const history = useHistory();
  const entries = Entries.useContainer();
  const [date, setDate] = useState<Moment | null>(null);
  const repeat = useFetcher(RepeatResource.repeat());
  const fetchEntries = useFetcher(EntryResource.listShape());

  const [loading, setLoading] = useState<boolean>(false);

  const navigateHome = () =>
    history.replace(`/day/${moment().format("YYYY-MM-DD")}`);

  const repeatHandler = () => {
    setLoading(true);

    const repeatEntries: any = {
      taskHours: []
    };

    entries.eData.entries.map((entry: any) => {
      const today = moment().format("YYYY-MM-DD");

      return repeatEntries.taskHours.push({
        ...entry,
        GroupCode: "1",
        TaskDate: today,
        StartTime: `${today} ${entry.StartTime}`,
        EndTime: `${today} ${entry.EndTime}`
      });
    });

    entries.ReloadToday();

    repeat({}, repeatEntries).then(() =>
      fetchEntries({}, {}).then(() => navigateHome())
    );
  };

  if (!entries.eData.repeatTabEnabled) {
    navigateHome();
    return null;
  }

  const isDateValid: boolean =
    !!date && date.format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD");

  const status: string = isDateValid
    ? `${date?.format("MMM DD")} is selected`
    : "Select a day to be repeated into today";

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <CalendarComponent day={date} selectDay={setDate} loading={loading} />
        <IonText
          style={{
            textAlign: "center",
            color: isDateValid ? "inherit" : "#fd3553"
          }}
        >
          <p style={{ marginBottom: 10, lineHeight: 1.2 }}>{status}</p>
        </IonText>

        {isDateValid && (
          <IonRow className="ion-justify-content-end">
            <IonButton
              className="fill-button"
              fill="solid"
              onClick={() => repeatHandler()}
              disabled={loading}
            >
              {loading ? "Saving..." : "Confirm"}
            </IonButton>
          </IonRow>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Repeat;
